import getDeviceType from "./helpers";

export const PROXY_SERVER_URL = "https://proxy-server-7biq.onrender.com";
export const API_URL = "https://testing.miranapp.com/api"; // the name was the problem
export const PLAN_AUTH_TOKEN = "Token 63397bdc60811415932247daf4df30aaa74de978";
export const API_VERSION = "2.2.10";
export const VERSION = "v1";
export const SUBSCRIPTION_PATH = "user/newsletter/subscribe";
export const DEFAULT_IMAGE = "https://media.istockphoto.com/id/1182489122/photo/every-time-you-open-a-book-you-learn-something.jpg?b=1&s=170667a&w=0&k=20&c=y1gZ1_vM8BWevnAjgU-0oEI3_bfjUo7Vrosd_msxMvo=";
export const APP_LINK = getDeviceType() === "desktop" ?
    "https://apps.apple.com/us/app/miran/id1396961211" : "https://miranapp.app.link/Eoqt0wlsJub";