// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Page_inner__NzybS {\n  overflow: hidden; }\n", "",{"version":3,"sources":["webpack://./src/components/Page/Page.module.sass"],"names":[],"mappings":"AAEA;EACI,gBAAgB,EAAA","sourcesContent":["@import \"../../styles/helpers\";\n\n.inner {\n    overflow: hidden; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "Page_inner__NzybS"
};
export default ___CSS_LOADER_EXPORT___;
